$color-customer: #f02711;
$color-customer-hover: darken($color-customer, 10%);
$color-customer-disabled: lighten($color-customer, 10%);
$color-accent: #4ce1b6;
$color-accent-hover: darken($color-accent, 10%);
$color-white: #ffffff;
$color-white-hover: darken($color-white, 10%);
$color-yellow: #f6da6e;
$color-yellow-hover: darken($color-yellow, 10%);
$color-red: #ff4861;
$color-red-hover: darken($color-red, 10%);
$color-blue: #70bbfd;
$color-blue-hover: darken($color-blue, 10%);
$color-gray: #787985;
$color-light-gray: #d8dfe9;
$color-green: #b8e986;
$color-green-hover: darken($color-green, 10%);
$color-silver: #cdccd6;
$color-gunmetal: #302946;
$color-mint: #4d4663;
$color-gray2: #f4f5f7;

:root {
  --color-customer: #{$color-customer};
  --color-customer-hover: #{$color-customer-hover};
  --color-customer-disabled: #{$color-customer-disabled};
  --color-accent: #{$color-accent};
  --color-accent-hover: #{$color-accent-hover};
  --color-yellow: #{$color-yellow};
  --color-yellow-hover: #{$color-yellow-hover};
  --color-white: #{$color-white};
  --color-white-hover: #{$color-white-hover};
  --color-red: #{$color-red};
  --color-red-hover: #{$color-red-hover};
  --color-blue: #{$color-blue};
  --color-blue-hover: #{$color-blue-hover};
  --color-green: #{$color-green};
  --color-green-hover: #{$color-green-hover};
  --color-gray: #{$color-gray};
  --color--light-gray: #{$color-light-gray};
  --color-silver: #{$color-silver};
  --color-gunmetal: #{$color-gunmetal};
  --color-mint: #{$color-mint};
  --color-gray2: #{$color-gray2};

  --primary-font-color: #000;
  --secondary-font-color: #5c5c5c;
  --link-color: #0066c0;
  --error-color: #f02711;
  --success-color: #008800;

  // --primary-border-color: #cdccd6;
  // --secondary-border-color: #d8dfe9;

  --primary-border-color: #cdccd6;
  --secondary-border-color: #EEEEEE;

  --contained-primary-button-text-color: #fff;

  --page-background-color: #ffffff;
  --paper-background-color: #fff;
  --modal-header-color: #eae9ec;
  --table-header-color: #eae9ec;

  --primary-icon-color: #666;
}
