.editorClass {
    margin-top: 0;
    padding: 20px 20px;
    overflow: scroll;
}

.toolbarClass {
    margin-bottom: 0;
}

.wrapperClass {
    border: 1px solid var(--primary-border-color);
    height: 280px;
    width: 730px;
}

.sectionborder {

    border-style: none;
    border-right: 1px solid var(--primary-border-color);
}

.iconborder {
    border-style: none;
}

.iconborderright {
    border-style: none;
    border-right: solid 1px var(--primary-border-color);
}

.iconborderleft {
    border-style: none;
    border-left: solid 1px var(--primary-border-color);
}

.toolbarIcon {
    border: 0
}

.toolbarIcon:hover {
    border: 0;
    box-shadow: "none";
}

.insertImage {
    background-color: "red";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill=%7Bfill%7D style=%7Bsx%7D %3E%3Cpath d='M8.139,6A2.148,2.148,0,0,0,6,8.139v9.722A2.148,2.148,0,0,0,8.139,20h9.722A2.148,2.148,0,0,0,20,17.861V8.139A2.148,2.148,0,0,0,17.861,6Zm0,1.167h9.722a.964.964,0,0,1,.972.972v7.955L16.5,13.832h0a1.573,1.573,0,0,0-2.187,0l-.8.771-2.4-2.325a1.57,1.57,0,0,0-2.188,0h0L7.167,13.972V8.139A.964.964,0,0,1,8.139,7.167Zm7.389,1.556a1.752,1.752,0,0,0-1.311.561,1.831,1.831,0,0,0,0,2.379,1.813,1.813,0,0,0,2.622,0,1.831,1.831,0,0,0,0-2.379A1.752,1.752,0,0,0,15.528,8.722Zm0,1.167a.513.513,0,0,1,.439.169.668.668,0,0,1,0,.829.656.656,0,0,1-.878,0,.668.668,0,0,1,0-.829A.513.513,0,0,1,15.528,9.889ZM10.013,13a.4.4,0,0,1,.283.115h0l2.375,2.3L9.136,18.833h-1a.964.964,0,0,1-.972-.972V15.6l2.563-2.479A.4.4,0,0,1,10.013,13ZM15.4,14.556a.4.4,0,0,1,.283.115l3.149,3.047v.144a.964.964,0,0,1-.972.972H10.815l4.3-4.163A.4.4,0,0,1,15.4,14.556Z' transform='translate(-6 -6)' /%3E%3C/svg%3E");
}