.ringing-bell {
    animation: ring 0.7s infinite;
}

@keyframes ring {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}


.shuffling-bell {
    animation: shuffle 2s linear infinite;
    transform-origin: center;
}

@keyframes shuffle {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.shake-effect {
    animation: shake 0.5s infinite;;
}

@keyframes shake {
    0% {
        transform: translate(0, 0);
    }
    20% {
        transform: translate(-1px, 0);
    }
    40% {
        transform: translate(1px, 0);
    }
    60% {
        transform: translate(-1px, 0);
    }
    80% {
        transform: translate(1px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}



/*.shuffling-bell {*/
/*    animation: shuffle 2s infinite;*/
/*    transform-origin: center;*/
/*}*/

/*@keyframes shuffle {*/
/*    0% {*/
/*        transform: rotate(0deg);*/
/*    }*/
/*    50% {*/
/*        transform: rotate(180deg);*/
/*    }*/
/*    100% {*/
/*        transform: rotate(360deg);*/
/*    }*/
/*}*/