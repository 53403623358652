@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir-Light.woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir-LightOblique.woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir-Book.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir-BookOblique.woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir-Medium.woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir-MediumOblique.woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir-Heavy.woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir-HeavyOblique.woff2');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir-Black.woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/Avenir-BlackOblique.woff2');
  font-weight: 900;
  font-style: italic;
}

body {
  margin: 0;
  padding: 0 !important;
  box-sizing: border-box;
  font-family: Avenir;
  font-size: 13px;
  width: 100%;
  height: 100%;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  overflow-y: scroll;
}

* {
  box-sizing: inherit;
  scrollbar-width: 0.7vh;
  scrollbar-height: 0.7vh;
  scrollbar-color: #ccc white;
}
*::-webkit-scrollbar {
  height: 0.7vh;
  width: 0.7vh;
  background-color: red;
}

*::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 2px;
}

*::-webkit-scrollbar-track {
  background-color: white;
}

textarea::placeholder {
  color: var(--secondary-font-color);
  opacity: 0.4;
}

a {
  color: #0066c0;
  text-decoration: none;
}

img {
  position: relative;
}

img:after {
  content: '';
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--secondary-border-color);
}

/* remvoe arrows from number field */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
/* Chrome, Safari, Edge, Opera */
*input::-webkit-outer-spin-button,
*input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
*input[type='number'] {
  -moz-appearance: textfield;
}

.cleave-form-field {
  align-items: center;
  width: 100%;
  position: relative;
  background-color: #fff;
  border: 1px solid var(--primary-border-color);
  outline: none;
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-font-color);
  height: 38px;
  padding: 3px 0 3px;
  border-radius: 4px;
  font: inherit;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding-left: 15px;
}

*input[type='date'] {
  cursor: pointer;
}

*input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.map-info-window-content {
  padding: 24px 8px 8px;
  min-width: 200px;
  h1,
  p,
  button {
    margin: 4px 0;
  }
  h1 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
  }
  button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    text-decoration: underline;
  }
}

.gm-style-iw-c {
  padding: 0 !important;
}

.gm-style-iw-chr {
  position: absolute;
  top: 2px;
  right: 2px;
  height: 26px;
  width: 26px;
  button {
    height: 26px !important;
    width: 26px !important;
    span {
      margin: 0 !important;
    }
  }
}

.gm-style-iw-d {
  overflow: hidden !important;
  max-height: inherit !important;
  max-width: 300px;
}
